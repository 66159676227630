import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { IParkingLotAddZoneData, IParkingLotListZoneData, useApi } from 'components/providers/ApiProvider'
import { usePermissions } from 'components/providers/PermissionProvider'
import { parkingLotModifyPermission } from 'utils'

type RootState = {
  addZones: IParkingLotAddZoneData[]
  listZones: IParkingLotListZoneData[]
  enableList: boolean | null
  loadList: number
  loadUnexpandedList: number
  listLoading: boolean
  searchByPlateNumber: string
  listPerPage: number
  listPage: number
  loadPayments: number
}

type RootFunctions = {
  setEnableList(status: boolean): void
  setLoadList(): void
  setListLoading(status: boolean): void
  setLoadSearchList(): void
  setSearchByPlateNumber(value: string): void
  setLoadSearchByPlateNumberList(): void
  setLoadChangePerPageList(perPage: number): void
  setLoadChangePageList(page: number): void
  setListPage(value: number): void
  setLoadEditSessionPlateNumberList(): void
  setLoadAttachPaymentList(): void
  setLoadRefundPaymentList(): void
  setLoadDetachPaymentList(): void
}

const InitialState: RootState = {
  addZones: [],
  listZones: [],
  enableList: null,
  loadList: 0,
  loadUnexpandedList: 0,
  listLoading: false,
  searchByPlateNumber: null,
  listPerPage: 20,
  listPage: 1,
  loadPayments: 0,
}

export const ParkingLotContext = React.createContext<[RootState, (state: any) => void]>([InitialState, () => {}])

export const ParkingLotProvider: FunctionComponent = ({ children }) => {
  const permissions = usePermissions()
  const api = useApi()

  const [state, setState] = useState<RootState>(InitialState)

  const hasParkingLotModifyPermission = parkingLotModifyPermission(permissions)

  useEffect(() => {
    if (hasParkingLotModifyPermission) {
      api.getParkingLotAddZones()
        .then(data => {
          setState((state: RootState) => ({
            ...state,
            addZones: data.zones,
          }))
        })
        .catch(() => {
        })
    }

    api.getParkingLotListZones()
      .then(data => {
        setState((state: RootState) => ({
          ...state,
          listZones: data.zones,
        }))
      })
      .catch(() => {
      })
  }, [])

  return (
    <ParkingLotContext.Provider value={[state, setState]}>
      {children}
    </ParkingLotContext.Provider>
  )
}

export const useParkingLot = (): RootFunctions & RootState => {
  const [state, setState] = useContext(ParkingLotContext)

  const setEnableList = (status: boolean) => {
    setState((state: RootState) => ({
      ...state,
      enableList: status,
    }))
  }

  const setLoadList = () => {
    setState((state: RootState) => ({
      ...state,
      enableList: true,
      loadList: state.loadList + 1,
    }))
  }

  const setListLoading = (status: boolean) => {
    setState((state: RootState) => ({
      ...state,
      listLoading: status,
    }))
  }

  const setLoadSearchList = () => {
    setState((state: RootState) => ({
      ...state,
      enableList: true,
      loadUnexpandedList: state.loadUnexpandedList + 1,
      listPage: 1,
    }))
  }

  const setSearchByPlateNumber = (value: string) => {
    setState((state: RootState) => ({
      ...state,
      searchByPlateNumber: value,
    }))
  }

  const setLoadSearchByPlateNumberList = () => {
    setState((state: RootState) => ({
      ...state,
      searchByPlateNumber: null,
      loadList: state.loadList + 1,
      listPage: 1,
    }))
  }

  const setLoadChangePerPageList = (perPage: number) => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      listPerPage: perPage,
      listPage: 1,
    }))
  }

  const setLoadChangePageList = (page: number) => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      listPage: page,
    }))
  }

  const setListPage = (value: number) => {
    setState((state: RootState) => ({
      ...state,
      listPage: value,
    }))
  }

  const setLoadEditSessionPlateNumberList = () => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      loadPayments: state.loadPayments + 1,
    }))
  }

  const setLoadAttachPaymentList = () => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      loadPayments: state.loadPayments + 1,
    }))
  }

  const setLoadRefundPaymentList = () => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      loadPayments: state.loadPayments + 1,
    }))
  }

  const setLoadDetachPaymentList = () => {
    setState((state: RootState) => ({
      ...state,
      loadList: state.loadList + 1,
      loadPayments: state.loadPayments + 1,
    }))
  }

  return {
    setEnableList,
    setLoadList,
    setListLoading,
    setLoadSearchList,
    setSearchByPlateNumber,
    setLoadSearchByPlateNumberList,
    setLoadChangePerPageList,
    setLoadChangePageList,
    setListPage,
    setLoadEditSessionPlateNumberList,
    setLoadAttachPaymentList,
    setLoadRefundPaymentList,
    setLoadDetachPaymentList,
    ...state,
  }
}
