import React, { FunctionComponent } from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export type Props = {
  id: string
  name: string
  label: string
  value: any
  required?: boolean
  size?: 'small' | 'medium'
  error?: string
  disabled: boolean
  options?: string[]
  optionsCustom?: { label: string | JSX.Element; id: any }[]
  onChange: (e: any) => void
  GridProps?: GridProps
}
export type Functions = {}
export type AllProps = Props & Functions

export const SelectField: FunctionComponent<AllProps> = ({
  GridProps,
  size,
  label,
  value,
  required,
  id,
  name,
  disabled,
  error,
  onChange,
  options,
  optionsCustom,
}) => (
  <Grid item {...GridProps}>
    <FormControl fullWidth variant="outlined" required={required} size={size} disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        error={Boolean(error)}
        onChange={onChange}
      >
        {optionsCustom
          ? optionsCustom.map((customOpts, i) => (
              <MenuItem key={i} value={customOpts.id}>
                {customOpts.label}
              </MenuItem>
            ))
          : options.map((label, i) => (
              <MenuItem key={i} value={i}>
                {label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  </Grid>
)

export default SelectField
