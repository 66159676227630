import React, { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { IParkingLotSessionAttachablePaymentsPaymentData, useApi } from 'components/providers/ApiProvider'
import { useParkingLot } from '../ParkingLotProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { useTranslation } from 'react-i18next'
import SelectField from 'components/elements/SelectField'
import Alert from '@material-ui/lab/Alert'
import { formatMoney, formatTimestamp } from 'utils'

export interface AttachPaymentDialogData {
  sessionId: number
}

type Props = {
  open: boolean
  data: AttachPaymentDialogData
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const AttachPaymentDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadAttachPaymentList } = useParkingLot()
  const { showSuccess, showError } = useMessage()

  const [payments, setPayments] = useState<IParkingLotSessionAttachablePaymentsPaymentData[]>(null)

  const handleEnter = () => {
    api.getParkingLotSessionAttachablePayments({
      session_id: data.sessionId,
    })
      .then(data => {
        setPayments(data.payments)

        if (data.payments.length == 1) {
          formal.change('payment', data.payments[0].id)
        }
      })
      .catch(() => {
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    setPayments(null)
    formal.change('payment', initialValues.payment)
    formal.clearErrors()
  }

  const initialValues = {
    payment: 0,
  }

  const schema = yup.object().shape({
    payment: yup.number().required().min(1),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      values = schema.cast(values)

      await api.attachParkingLotSessionPayment({
        session_id: data.sessionId,
        payment_id: values.payment,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          setLoadAttachPaymentList()

          showSuccess(t('messages.paymentAttached'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotAttachPayment')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('attachPayment')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container>
            {payments && (
              <>
                {Boolean(payments.length) ? (
                  <SelectField
                    GridProps={{ xs: 12 }}
                    label={t('payment')}
                    required
                    optionsCustom={[
                      {
                        label: t('select'),
                        id: 0,
                      },
                      ...payments.map(payment => ({
                        id: payment.id,
                        label: `${formatMoney(payment.amount)} @ ${formatTimestamp(payment.paid_at)} (${formatTimestamp(payment.entry_at)} - ${formatTimestamp(payment.exit_at)})`,
                      })),
                    ]}
                    {...formal.getFieldProps('payment')}
                  />
                ) : (
                  <Grid item xs={12} style={{ padding: '0 12px' }}>
                    <Alert severity="info">
                      {t('noPayments')}
                    </Alert>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          {payments && Boolean(payments.length) && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formal.isSubmitting}
            >
              {t('attach')}
            </Button>
          )}
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default AttachPaymentDialog
