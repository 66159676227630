import React, { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { DateTimeField } from 'components/elements/DateTimeField'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { SelectField } from 'components/elements/SelectField'
import { useTranslation } from 'react-i18next'
import { useApi } from 'components/providers/ApiProvider'
import { formatDateTimeFieldToISO, isDateTimeFieldValid, parseDateTimeField, yupDateTimeField } from 'utils'
import { useParkingLot } from '../ParkingLotProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { Checkbox, Typography } from '@material-ui/core'
import { PlateNumberField } from 'components/elements/PlateNumberField'
import FormControlLabel from '@material-ui/core/FormControlLabel'

type Props = {
  open: boolean
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const AddSessionDialog: FunctionComponent<AllProps> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation()
  const api = useApi()
  const { addZones: zones, setLoadList } = useParkingLot()
  const { showSuccess, showError } = useMessage()

  const [entryCameraOptions, setEntryCameraOptions] = useState([])
  const [exitCameraOptions, setExitCameraOptions] = useState([])

  const handleClose = () => {
    setOpen(false)
  }

  const initialValues = {
    zone: 0,
    plateNumber: '',
    entryAt: '',
    entryCamera: 0,
    exitAt: '',
    exitCamera: 0,
    startAutopay: false,
  }

  const schema = yup.object().shape({
    zone: yup.number().required().min(1),
    plateNumber: yup.string().trim().required(),
    entryAt: yupDateTimeField({ required: true }).test({
      test: value => {
        if (value == '') {
          return true
        }

        const valueDate = parseDateTimeField(value)

        if (formal.values.exitAt != '' && isDateTimeFieldValid(formal.values.exitAt) && valueDate >= parseDateTimeField(formal.values.exitAt)) {
          return false
        }

        return valueDate <= (new Date)
      },
    }),
    entryCamera: yup.number().required().min(1),
    exitAt: yupDateTimeField().test({
      test: value => {
        if (value == '') {
          return true
        }

        const valueDate = parseDateTimeField(value)

        if (formal.values.entryAt != '' && isDateTimeFieldValid(formal.values.entryAt) && valueDate <= parseDateTimeField(formal.values.entryAt)) {
          return false
        }

        return valueDate <= (new Date)
      },
    }),
    exitCamera: yup.number().test({
      test: value => {
        return !(formal.values.exitAt.trim() != '' && value == 0)
      },
    }),
    startAutopay: yup.boolean(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      await api.createParkingLotSession({
        zone_id: values.zone,
        plate_number: values.plateNumber.trim().toUpperCase(),
        entry_at: formatDateTimeFieldToISO(values.entryAt.trim()),
        entry_camera_id: values.entryCamera,
        ...(values.exitAt.trim() && { exit_at: formatDateTimeFieldToISO(values.exitAt.trim()) }),
        ...(values.exitCamera && { exit_camera_id: values.exitCamera }),
        ...(values.startAutopay && { start_autopay: true }),
      })
        .then(response => {
          if (!response || !response.status) {
            throw new Error
          }

          handleClose()

          // Reset camera selects before zone
          formal.change('entryCamera', initialValues.entryCamera)
          formal.change('exitCamera', initialValues.exitCamera)

          formal.change('zone', initialValues.zone)
          formal.change('plateNumber', initialValues.plateNumber)
          formal.change('entryAt', initialValues.entryAt)
          formal.change('exitAt', initialValues.exitAt)
          formal.change('startAutopay', initialValues.startAutopay)

          setEntryCameraOptions([])
          setExitCameraOptions([])

          setLoadList()

          showSuccess(t('messages.parkingSessionCreated'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 12:
              message = t('alerts.parkingSessionOverlaps')
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotCreateParkingSession')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog open={open} onClose={handleClose}>
      <DraggableDialogTitle>{t('newParkingSession')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            <SelectField
              GridProps={{ xs: 12 }}
              label={t('zone')}
              required
              optionsCustom={[
                {
                  label: t('select'),
                  id: 0,
                },
                ...zones.sort((a, b) => a.code.localeCompare(b.code)).map(zone => {
                  const localization = zone.localizations.find(l => l.language == i18n.language)

                  return {
                    label: `${zone.code}${localization && localization.name ? ` ${localization.name}` : ''}`,
                    id: zone.id,
                  }
                }),
              ]}
              {...formal.getFieldProps('zone')}
              onChange={e => {
                const value = e.target.value

                formal.change('zone', value)

                if (!value) {
                  formal.change('entryCamera', 0)
                  formal.change('exitCamera', 0)
                  setEntryCameraOptions([])
                  setExitCameraOptions([])

                  return
                }

                const zoneCameras = zones.find(zone => zone.id == value).cameras

                const entryCameraOptions = zoneCameras.filter(camera => camera.type != 'exit').map(camera => ({
                  label: camera.code,
                  id: camera.id,
                }))

                const exitCameraOptions = zoneCameras.filter(camera => camera.type != 'entry').map(camera => ({
                  label: camera.code,
                  id: camera.id,
                }))

                formal.change('entryCamera', entryCameraOptions.length == 1 ? entryCameraOptions.pop().id : 0)
                formal.change('exitCamera', 0)
                setEntryCameraOptions(entryCameraOptions)
                setExitCameraOptions(exitCameraOptions)
              }}
            />
            <PlateNumberField
              GridProps={{ xs: 12 }}
              required
              {...formal.getFieldProps('plateNumber')}
            />
            <Grid item xs={12}>
              <Typography style={{ marginTop: '8px' }}>{t('droveIn')}</Typography>
            </Grid>
            <DateTimeField
              GridProps={{ xs: 6 }}
              label={t('time')}
              required
              {...formal.getFieldProps('entryAt')}
              disableFuture={true}
              {
                ...(formal.values.exitAt && { maxDate: parseDateTimeField(formal.values.exitAt) })
              }
            />
            <SelectField
              GridProps={{ xs: 6 }}
              label={t('camera')}
              required
              optionsCustom={[
                {
                  label: entryCameraOptions.length > 1 ? t('select') : '...',
                  id: 0,
                },
                ...(!formal.values.zone ? [] : zones.find(zone => zone.id == formal.values.zone).cameras.filter(camera => camera.type != 'exit').map(camera => ({
                  label: camera.code,
                  id: camera.id,
                }))),
              ]}
              {...formal.getFieldProps('entryCamera')}
              disabled={entryCameraOptions.length <= 1 || formal.isSubmitting}
            />
            <Grid item xs={12}>
              <Typography style={{ marginTop: '8px' }}>{t('droveOut')}</Typography>
            </Grid>
            <DateTimeField
              GridProps={{ xs: 6 }}
              label={t('time')}
              {...formal.getFieldProps('exitAt')}
              disableFuture={true}
              {
                ...(formal.values.entryAt && { minDate: parseDateTimeField(formal.values.entryAt) })
              }
            />
            <SelectField
              GridProps={{ xs: 6 }}
              label={t('camera')}
              optionsCustom={[
                {
                  label: exitCameraOptions.length >= 1 ? t('select') : '...',
                  id: 0,
                },
                ...(!formal.values.zone ? [] : zones.find(zone => zone.id == formal.values.zone).cameras.filter(camera => camera.type != 'entry').map(camera => ({
                  label: camera.code,
                  id: camera.id,
                }))),
              ]}
              {...formal.getFieldProps('exitCamera')}
              disabled={exitCameraOptions.length < 1 || formal.isSubmitting}
            />
            <Grid item xs={12} style={{ paddingLeft: 19 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...formal.getFieldProps('startAutopay')}
                    checked={formal.values.startAutopay}
                    color="primary"
                    onChange={(event) => formal.change('startAutopay', event.target.checked)}
                  />
                }
                label={t('startAutopay')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('add')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default AddSessionDialog
