import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { useApi } from 'components/providers/ApiProvider'
import { useMessage } from 'Elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'Elements/DraggableDialog'
import { PlateNumberField } from 'Elements/PlateNumberField'
import { useTranslation } from 'react-i18next'
import { useParkingPayments } from 'ParkingPayments/ParkingPaymentsProvider'

export interface EditPlateNumberDialogData {
  id: number
  plateNumber: string
}

type Props = {
  open: boolean
  data: EditPlateNumberDialogData
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const EditPlateNumberDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadList, setSearchByPlateNumber } = useParkingPayments()
  const { showSuccess, showError } = useMessage()
  let submitAndSearch = false

  const handleEnter = () => {
    formal.change('plateNumber', data.plateNumber)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    formal.change('plateNumber', initialValues.plateNumber)
    formal.clearErrors()
  }

  const handleSubmitAndSearch = () => {
    submitAndSearch = true
    formal.submit()
  }

  const initialValues = {
    plateNumber: '',
  }

  const schema = yup.object().shape({
    plateNumber: yup.string().required(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      values = schema.cast(values)

      const plateNumber = values.plateNumber.toUpperCase()

      await api.updateParkingPaymentPlateNumber({
        external_payment_id: data.id,
        plate_number: plateNumber,
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          if (submitAndSearch) {
            setSearchByPlateNumber(plateNumber)
          } else {
            setLoadList()
          }

          showSuccess(t('messages.plateNumberChanged'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 15:
              message = t('alerts.paymentIsAttachedToParkingSession')
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotChangePlateNumber')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('editPlateNumber')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container>
            <PlateNumberField
              GridProps={{ xs: 12 }}
              required
              {...formal.getFieldProps('plateNumber')}
              autoFocus={true}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('edit')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={formal.isSubmitting}
            onClick={handleSubmitAndSearch}
          >
            {t('editAndSearch')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default EditPlateNumberDialog
