import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParkingPayments } from 'ParkingPayments/ParkingPaymentsProvider'
import * as yup from 'yup'
import useFormal from '@kevinwolf/formal-web'
import SelectField from 'Elements/SelectField'
import { formatDateTimeFieldToISO, isDateTimeFieldValid, parseDateTimeField, yupDateTimeField } from 'utils'
import { DateTimeField } from 'Elements/DateTimeField'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import SearchIcon from '@material-ui/icons/Search'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useApi } from 'components/providers/ApiProvider'
import download from 'downloadjs'
import { PlateNumberField } from 'Elements/PlateNumberField'

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const Toolbar: FunctionComponent<AllProps> = ({}) => {
  const { t, i18n } = useTranslation()
  const { filter, setFilter, searchByPlateNumber, setLoadSearchList, setLoadSearchByPlateNumberList, listLoading, listZones } = useParkingPayments()
  const api = useApi()

  const [openSearchButtonDropdown, setOpenSearchButtonDropdown] = useState(false)
  const searchButtonRef = useRef(null)
  let exportClicked = false
  const [exportLoading, setExportLoading] = useState(false)

  useEffect(() => {
    if (typeof searchByPlateNumber != 'string') {
      return
    }

    formal.change('plateNumber', searchByPlateNumber)

    setLoadSearchByPlateNumberList()
  }, [searchByPlateNumber])

  const handleSearchButtonDropdownToggle = () => {
    setOpenSearchButtonDropdown((prevOpen) => !prevOpen)
  }

  const handleSearchButtonDropdownClose = (event) => {
    if (searchButtonRef.current && searchButtonRef.current.contains(event.target)) {
      return
    }

    setOpenSearchButtonDropdown(false)
  }

  const handleExportClick = () => {
    exportClicked = true
    formal.submit()
  }

  const schema = yup.object().shape({
    zone: yup.number(),
    plateNumber: yup.string(),
    paidFrom: yupDateTimeField().test({
      test: value => {
        if (value == '') {
          return true
        }

        const valueDate = parseDateTimeField(value)

        if (formal.values.paidTill != '' && isDateTimeFieldValid(formal.values.paidTill) && valueDate >= parseDateTimeField(formal.values.paidTill)) {
          return false
        }

        return valueDate <= (new Date)
      },
    }),
    paidTill: yupDateTimeField().test({
      test: value => {
        if (value == '') {
          return true
        }

        const valueDate = parseDateTimeField(value)

        if (formal.values.paidFrom != '' && isDateTimeFieldValid(formal.values.paidFrom) && valueDate <= parseDateTimeField(formal.values.paidFrom)) {
          return false
        }

        return true
      },
    }),
    state: yup.string(),
  })

  const formal = useFormal(filter, {
    schema,
    onSubmit: async values => {
      if (exportClicked) {
        setExportLoading(true)

        await api.exportParkingPaymentsList({
          zone_id: values.zone || undefined,
          plate_number: filter.plateNumber.toUpperCase() || undefined,
          ...(values.paidFrom && { paid_from: formatDateTimeFieldToISO(values.paidFrom) }),
          ...(values.paidTill && { paid_till: formatDateTimeFieldToISO(values.paidTill) }),
          state: values.state || undefined,
        })
          .then(blob => {
            setExportLoading(false)

            download(blob, 'parking-payments.xlsx')
          })
          .catch(() => {
            setExportLoading(false)

            alert(`${t('alerts.couldNotExportData')} ${t('alerts.tryLater')}`)
          })
      } else {
        setFilter(schema.cast(values))
        setLoadSearchList()
      }
    },
  })

  return (
    <>
      <form {...formal.getFormProps()}>
        <Grid container spacing={1} style={{ padding: '16px 0' }}>
          <Grid item xs={12} md={true}>
            <Grid container spacing={1}>
              <SelectField
                GridProps={{ xs: 6, lg: 3 }}
                label={t('zone')}
                optionsCustom={[
                  {
                    label: t('allZones'),
                    id: 0,
                  },
                  ...listZones.sort((a, b) => a.code.localeCompare(b.code)).map(zone => {
                    const localization = zone.localizations.find(l => l.language == i18n.language)

                    return {
                      label: `${zone.code}${localization && localization.name ? ` ${localization.name}` : ''}`,
                      id: zone.id,
                    }
                  }),
                ]}
                size="small"
                {...formal.getFieldProps('zone')}
              />
              <PlateNumberField
                GridProps={{ xs: 6, md: 3 }}
                clearable={true}
                size="small"
                {...formal.getFieldProps('plateNumber')}
              />
              <DateTimeField
                GridProps={{ xs: 12, sm: 6, lg: 3 }}
                label={`${t('paidAt')} - ${t('from')}`}
                size="small"
                {...formal.getFieldProps('paidFrom')}
                initialFocusedDate={(new Date).setHours(0, 0, 0, 0)}
                {
                  ...(formal.values.paidTill && { maxDate: parseDateTimeField(formal.values.paidTill) })
                }
                clearable
              />
              <DateTimeField
                GridProps={{ xs: 12, sm: 6, lg: 3 }}
                label={`${t('paidAt')} - ${t('till')}`}
                size="small"
                {...formal.getFieldProps('paidTill')}
                initialFocusedDate={(new Date).setHours(23, 59, 59, 99)}
                {
                  ...(formal.values.paidFrom && { minDate: parseDateTimeField(formal.values.paidFrom) })
                }
                clearable
              />
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup row {...formal.getFieldProps('state')} style={{ paddingLeft: '12px' }}>
                    <FormControlLabel value="" control={<Radio />} label={t('all')} />
                    <FormControlLabel value="attached" control={<Radio />} label={t('attached')} />
                    <FormControlLabel value="unattached" control={<Radio />} label={t('unattached')} />
                    <FormControlLabel value="attachable" control={<Radio />} label={t('attachable')} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <ButtonGroup variant="contained" color="primary" fullWidth ref={searchButtonRef} style={{ height: '100%', maxHeight: 40 }}>
              <Button
                type="submit"
                startIcon={<SearchIcon />}
                disabled={listLoading}
              >
                {t('search')}
              </Button>
              <Button
                size="small"
                style={{ flex: 1 }}
                onClick={handleSearchButtonDropdownToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper open={openSearchButtonDropdown} placement="bottom-end" anchorEl={searchButtonRef.current} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'right top',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleSearchButtonDropdownClose}>
                      <MenuList>
                        <MenuItem
                          disabled={exportLoading}
                          onClick={() => handleExportClick()}
                        >
                          {`${t('export')} (XLSX)`}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default Toolbar
