import React, { FunctionComponent, Ref, useEffect, useRef, useState } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { IParkingLotListEventData, IParkingLotListZoneData, ParkingLotListEventType, useApi } from 'components/providers/ApiProvider'
import { formatMoney, formatDateTimeFieldToISO } from 'utils'
import { useParkingLot } from '../ParkingLotProvider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import IconCancel from '@material-ui/icons/Eject'
import IconPlay from '@material-ui/icons/PlayCircleFilled'
import IconArchive from '@material-ui/icons/Archive'
import IconUnarchive from '@material-ui/icons/Unarchive'
import IconStop from '@material-ui/icons/Stop'
import IconArrowDropDown from '@material-ui/icons/ArrowDropDown'
import IconEdit from '@material-ui/icons/Edit'
import IconSwap from '@material-ui/icons/SyncAlt'
import IconTimer from '@material-ui/icons/Timer'
import IconError from '@material-ui/icons/ErrorOutline'
import IconSubscription from '@material-ui/icons/LocalActivity'
import IconSearch from '@material-ui/icons/Search'
import IconSearchInNewWindow from '@material-ui/icons/Pageview'
import IconCamera from '@material-ui/icons/Videocam'
import IconPayment from '@material-ui/icons/Payment'
import IconInfo from '@material-ui/icons/InfoOutlined'
import IconSelected from '@material-ui/icons/MoreVert'
import { ImageZoom } from 'components/elements/ImageZoom'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { ImagePlaceholder, ListTableCell, ListPlateNumberImageTableCell, ListExpandTableCell, ListItemActionButton, ListArchiveEventButton, ShowImageThumbnailsToggleButton, useClasses, ListArchiveEventButtonGroup } from '../ParkingLot.css'
import { Box, Checkbox, ClickAwayListener, Container, Grid, Grow, LinearProgress, MenuItem, MenuItemProps, MenuList, Popper } from '@material-ui/core'
import { useMessage } from 'components/elements/Message'
import StopSessionDialog, { DialogSession } from './StopSessionDialog'
import EditTotalAmountDialog, { DialogSession as TotalAmountDialogSession } from './EditTotalAmountDialog'
import EditPlateNumberDialog, { DialogEvent } from './EditPlateNumberDialog'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { theme } from 'components/providers/ThemeProvider'
import ListIconButton from 'Elements/ListIconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AutomaticIcon from 'components/views/parking-lot/elements/AutomaticIcon'
import SpecialRateIcon from 'components/views/parking-lot/elements/MoneyOffIcon'
import RecalculateIcon from '@material-ui/icons/Refresh'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import StartSessionDialog, { DialogSession as StartSessionDialogSession } from 'components/views/parking-lot/partials/StartSessionDialog'
import EditSpecialRateDialog, { EditSpecialRateDialogData } from 'components/views/parking-lot/partials/EditSpecialRateDialog'
import IconImage from '@material-ui/icons/Image'
import { TablePaginationActions } from 'components/elements/TablePaginationActions'
import ListSessionPayments from 'components/views/parking-lot/partials/ListSessionPayments'
import { differenceInMinutes, format, intervalToDuration } from 'date-fns'
import Paper from '@material-ui/core/Paper'
import AttachPaymentDialog, { AttachPaymentDialogData } from 'components/views/parking-lot/partials/AttachPaymentDialog'
import EditSessionPlateNumberDialog, { EditSessionPlateNumberDialogData } from 'components/views/parking-lot/partials/EditSessionPlateNumberDialog'
import Alert from '@material-ui/lab/Alert'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const EventDatetime = ({ value }: { value: string }) => {
  const date = new Date(value)

  return <>{format(date, 'dd.MM.yyyy HH:mm')}<small>:{('0' + date.getSeconds()).slice(-2)}</small></>
}

const EventCameraName = ({ value }: { value: string }) => {
  return <><span style={{ fontSize: 0, marginLeft: 12 }}>{' '}</span><span><IconCamera fontSize="small" style={{ margin: '-1px 4px -5px 0' }} />{value}</span></>
}

const EventPlateNumberOriginal = ({ value }: { value: string }) => {
  return <><span style={{ fontSize: 0, marginLeft: 12 }}>{' '}</span><span style={{ fontFamily: '"Roboto Mono", monospace', textDecoration: 'line-through' }}>{value}</span></>
}

const SessionDuration = ({ entry, exit }: { entry: string, exit: string | Date }) => {
  const duration = intervalToDuration({
    start: new Date(entry),
    end: typeof exit == 'string' ? new Date(exit) : exit,
  })

  let result = ''
  if (duration.years) result += `${duration.years} g. `
  if (duration.months) result += `${duration.months} mēn. `
  if (duration.days) result += `${duration.days} d. `
  if (duration.hours) result += `${duration.hours} st. `

  return <>{result}{('0' + duration.minutes).slice(-2)}<small>:{('0' + duration.seconds).slice(-2)}</small></>
}

const EventArchiveButton = ({ event, handleArchive, noBorderTopRadius }) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleDropdownToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen)
  }

  const handleDropdownClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (ref.current && ref.current.contains(event.target)) {
      return
    }

    setOpenDropdown(false)
  }

  return <>
    <ListArchiveEventButtonGroup
      fullWidth
      style={{ ...(noBorderTopRadius && { borderTopLeftRadius: 0, borderTopRightRadius: 0 }) }}
      ref={ref}
    >
      <Button
        startIcon={<IconArchive />}
        disableElevation
        style={{ justifyContent: 'left', paddingRight: 8, ...(noBorderTopRadius && { borderTopLeftRadius: 0 }) }}
        onClick={() => handleArchive(event)}
      >
        {t('archive')}
      </Button>
      <Button
        size="small"
        disableElevation
        style={{ flex: 1, ...(noBorderTopRadius && { borderTopRightRadius: 0 }) }}
        onClick={handleDropdownToggle}
      >
        <IconArrowDropDown />
      </Button>
    </ListArchiveEventButtonGroup>
    <Popper open={openDropdown} placement="bottom-end" anchorEl={ref.current} transition disablePortal style={{ zIndex: 1 }}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
          <Paper>
            <ClickAwayListener onClickAway={handleDropdownClose}>
              <MenuList>
                <MenuItem onClick={() => handleArchive(event, true)}>
                  {t('archiveAndIgnore')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}

const SessionStopButton = ({ event, handleStop, handleStopWithoutEvent }) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleDropdownToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen)
  }

  const handleDropdownClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (ref.current && ref.current.contains(event.target)) {
      return
    }

    setOpenDropdown(false)
  }

  return <>
    <ButtonGroup
      fullWidth
      style={{ boxShadow: 'none' }}
      ref={ref}
    >
      <Button
        startIcon={<IconStop />}
        disableElevation
        style={{ justifyContent: 'left', paddingRight: 8, borderRadius: 0 }}
        onClick={() => handleStop(event)}
      >
        {t('stop')}
      </Button>
      <Button
        size="small"
        disableElevation
        style={{ flex: 1, borderRadius: 0 }}
        onClick={handleDropdownToggle}
      >
        <IconArrowDropDown />
      </Button>
    </ButtonGroup>
    <Popper open={openDropdown} placement="bottom-end" anchorEl={ref.current} transition disablePortal style={{ zIndex: 1 }}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
          <Paper>
            <ClickAwayListener onClickAway={handleDropdownClose}>
              <MenuList>
                <MenuItem onClick={(e) => handleStopWithoutEvent(e, event)}>
                  {t('stopWithoutEvent')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}

const SessionCancelButton = ({ event, handleCancel }) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleDropdownToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen)
  }

  const handleDropdownClose = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return
    }

    setOpenDropdown(false)
  }

  return <>
    <ButtonGroup
      variant="contained"
      color="secondary"
      fullWidth
      style={{ boxShadow: 'none' }}
      ref={ref}
    >
      <Button
        startIcon={<IconCancel />}
        disableElevation
        style={{ justifyContent: 'left', paddingRight: 8, borderTopLeftRadius: 0 }}
        onClick={(e) => handleCancel(e, event)}
      >
        {t('parkingSession.cancel')}
      </Button>
      <Button
        size="small"
        disableElevation
        style={{ flex: 1, borderTopRightRadius: 0 }}
        onClick={handleDropdownToggle}
      >
        <IconArrowDropDown />
      </Button>
    </ButtonGroup>
    <Popper open={openDropdown} placement="bottom-end" anchorEl={ref.current} transition disablePortal>
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: 'right top' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleDropdownClose}>
              <MenuList>
                <MenuItem onClick={(e) => handleCancel(e, event, true)}>
                  {t('cancelAndArchive')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}

const outputEventZone = (zone: IParkingLotListZoneData) => {
  if (!zone) {
    return null
  }

  return <>{zone.code}</>
}

const WithSelectedMenuItem = React.forwardRef(({ icon, text, ...props }: Omit<MenuItemProps, 'button'> & { icon: React.ReactElement, text: string }, ref: Ref<HTMLLIElement>) => {
  return <MenuItem {...props} ref={ref}>
    <ListItemIcon style={{ minWidth: 28 }}>
      {icon}
    </ListItemIcon>
    <ListItemText primary={text} color="secondary" style={{ textTransform: 'uppercase' }} />
  </MenuItem>
})

type Props = {}
type Functions = {}
type AllProps = Props & Functions

const List: FunctionComponent<AllProps> = ({}) => {
  const { t } = useTranslation()
  const api = useApi()
  const history = useHistory()
  const { listZones: zones, setEnableList, enableList, loadList, loadUnexpandedList, listLoading, setListLoading, setSearchByPlateNumber, listPerPage, setLoadChangePerPageList, listPage, setListPage, setLoadChangePageList } = useParkingLot()
  const { fadeTableRow, plateNumber: plateNumberClass } = useClasses()

  const [loading, setLoading] = useState<string[]>([])

  const [modify, setModify] = useState(false)
  const [events, setEvents] = useState<IParkingLotListEventData[] | null>(null)
  const [totalEvents, setTotalEvents] = useState(0)
  const [showImageThumbnails, setShowImageThumbnails] = useState(false)
  const [expand, setExpand] = useState(0)
  const [selected, setSelected] = useState([])
  const [withSelectedEl, setWithSelectedEl] = useState(null)
  const [durationNowDate, setDurationNowDate] = useState(new Date)

  const [openStartSessionDialog, setOpenStartSessionDialog] = useState(false)
  const [startSessionSession, setStartSessionSession] = useState<StartSessionDialogSession>(null)

  const [openStopSessionDialog, setOpenStopSessionDialog] = useState(false)
  const [stopSessionSession, setStopSessionSession] = useState<DialogSession | null>(null)

  const [openEditPlateNumberDialog, setOpenEditPlateNumberDialog] = useState(false)
  const [editPlateNumberEvent, setEditPlateNumberEvent] = useState<DialogEvent | null>(null)

  const [openEditSessionPlateNumberDialog, setOpenEditSessionPlateNumberDialog] = useState(false)
  const [editSessionPlateNumberDialogData, setEditSessionPlateNumberDialogData] = useState<EditSessionPlateNumberDialogData | null>(null)

  const [openEditTotalAmountDialog, setOpenEditTotalAmountDialog] = useState(false)
  const [editTotalAmountSession, setEditTotalAmountSession] = useState<TotalAmountDialogSession | null>(null)

  const [openEditSpecialRateDialog, setOpenEditSpecialRateDialog] = useState(false)
  const [editSpecialRateDialogData, setEditSpecialRateDialogData] = useState<EditSpecialRateDialogData | null>(null)

  const [openAttachPaymentDialog, setOpenAttachPaymentDialog] = useState(false)
  const [attachPaymentDialogData, setAttachPaymentDialogData] = useState<AttachPaymentDialogData | null>(null)

  const { showSuccess, showError } = useMessage()

  const load = (onLoad?: Function, page?: number) => {
    if (!page) {
      setListLoading(true)
    }

    const filter = queryString.parse(location.search)

    api.getParkingLotList({
      ...(typeof filter.zone == 'string' && { zone_id: parseInt(filter.zone) }),
      ...(typeof filter.plateNumber == 'string' && { plate_number: filter.plateNumber }),
      ...(typeof filter.dateFrom == 'string' && { occurred_from: formatDateTimeFieldToISO(filter.dateFrom) }),
      ...(typeof filter.dateTill == 'string' && { occurred_till: formatDateTimeFieldToISO(filter.dateTill) }),
      ...(typeof filter.state == 'string' && { state: filter.state }),
      limit: listPerPage,
      page: page || listPage,
    })
      .then(data => {
        if (!page && data.events && !data.events.length && data.totalEvents) {
          const pages = Math.ceil(data.totalEvents / listPerPage)

          if (listPage > pages) {
            setListPage(pages)
            load(onLoad, pages)

            return
          }
        }

        setListLoading(false)

        if (onLoad) {
          onLoad(data.events)
        }

        setSelected([])
        setModify(Boolean(data.modify))
        setEvents(data.events || [])
        setTotalEvents(data.totalEvents)
      })
      .catch(error => {
        setListLoading(false)

        if (error.status == 401) {
          history.push('/')

          return
        }

        showError(`${t('alerts.couldNotLoadData')} ${t('alerts.tryLater')}`)
      })
  }

  useEffect(() => {
    let interval = setInterval(() => {
      setDurationNowDate(new Date)
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const filter = queryString.parse(location.search)

    if (filter.zone || filter.plateNumber || filter.dateFrom || filter.dateTill || filter.state) {
      setEnableList(true)
      load()
    }
  }, [])

  useEffect(() => {
    if (enableList || !zones.length) {
      return
    }

    if (zones.length == 1) {
      setEnableList(true)
      load()
    } else {
      setEnableList(false)
    }
  }, [zones])

  useEffect(() => {
    loadList && load()
  }, [loadList])

  useEffect(() => {
    loadUnexpandedList && load(() => {
      setExpand(0)
    })
  }, [loadUnexpandedList])

  const isLoading = (action: string) => loading.indexOf(action) >= 0

  const startLoading = (action: string) => {
    if (isLoading(action)) {
      return
    }

    setLoading([action])
  }

  const stopLoading = (action: string) => {
    setLoading([])
  }

  const handleExpand = (id: number) => {
    setExpand(id == expand ? 0 : id)
  }

  const handleSelectAll = (e) => {
    const newSelected = e.target.checked ? events.map(event => event.id) : []

    setSelected(newSelected)
  }

  const handleSelect = (id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1

  const handleWithSelected = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setWithSelectedEl(event.currentTarget)
  }

  const handleWithSelectedClose = () => {
    setWithSelectedEl(null)
  }

  const selectedEventsCount = (events || []).filter(event => !event.parking_session_id && !event.archived && selected.indexOf(event.id) !== -1).length
  const selectedArchivedEventsCount = (events || []).filter(event => !event.parking_session_id && event.archived && selected.indexOf(event.id) !== -1).length

  const handleStart = (event: IParkingLotListEventData) => {
    setStartSessionSession({ eventId: event.id, zoneId: event.zone_id, plateNumber: event.plate_number, entryAt: event.occurred_at })
    setOpenStartSessionDialog(true)
  }

  const handleEditSpecialRate = (event: IParkingLotListEventData) => {
    setEditSpecialRateDialogData({ sessionId: event.parking_session_id, sessionHasSpecialRate: event.parking_session_has_special_rate })
    setOpenEditSpecialRateDialog(true)
  }

  const handleRecalculateAmount = (event: IParkingLotListEventData) => {
    if (!confirm(t('confirms.recalculateAmount'))) {
      return
    }

    const action = 'recalculate'
    startLoading(action)

    api.recalculateParkingLotSessionAmounts({
      session_id: event.parking_session_id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.amountRecalculated'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotRecalculateAmount'))
      })
  }

  const handleAttachPayment = (event: IParkingLotListEventData) => {
    setAttachPaymentDialogData({ sessionId: event.parking_session_id })
    setOpenAttachPaymentDialog(true)
  }

  const handleStartAutopay = (event: IParkingLotListEventData) => {
    if (!confirm(t('confirms.startAutopay'))) {
      return
    }

    const action = 'start-autopay'
    startLoading(action)

    api.startParkingLotAutopay({
      session_id: event.parking_session_id,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.autopayStarted'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotStartAutopay'))
      })
  }

  const handleStop = (event: IParkingLotListEventData) => {
    setStopSessionSession({ id: event.parking_session_id, zoneId: event.zone_id, plateNumber: event.plate_number, entryAt: event.occurred_at })
    setOpenStopSessionDialog(true)
  }

  const handleStopWithoutEvent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, event: IParkingLotListEventData) => {
    if (!e.altKey && !confirm(t('confirms.stopParkingSession'))) {
      return
    }

    api.stopParkingLotSession({
      session_id: event.parking_session_id,
      event_id: null,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load()

        showSuccess(t('messages.parkingSessionStopped'))
      })
      .catch(error => {
        let message: string

        switch (error.code) {
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotStopParkingSession')} ${message}`)
      })
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, event: IParkingLotListEventData, archive?: boolean) => {
    if (!e.altKey && !confirm(t('confirms.cancelParkingSession'))) {
      return
    }

    api.cancelParkingLotSession({
      session_id: event.parking_session_id,
      ...(archive && { archive: true }),
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load()

        showSuccess(t('messages.parkingSessionCanceled'))
      })
      .catch(error => {
        let message: string

        switch (error.code) {
          case 13:
            message = t('alerts.parkingSessionHasUnattachablePayments')
            break
          default:
            message = t('alerts.tryLater')
        }

        showError(`${t('alerts.couldNotCancelParkingSession')} ${message}`)
      })
  }

  const handleSearchPlateNumber = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, plateNumber: string) => {
    event.stopPropagation()

    if (event.altKey && plateNumber.length >= 4) {
      plateNumber = plateNumber.substr(1, plateNumber.length - 2)
    }

    setSearchByPlateNumber(plateNumber)
  }

  const handleSearchPlateNumberInNewWindow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, zoneId: number, plateNumber: string) => {
    const parsedUrl = queryString.parseUrl(location.href)

    if (event.altKey && plateNumber.length >= 4) {
      plateNumber = plateNumber.substr(1, plateNumber.length - 2)
    }

    const url = {
      url: parsedUrl.url,
      query: {
        zone: zoneId.toString(),
        plateNumber,
      },
    }

    window.open(queryString.stringifyUrl(url, { sort: false }), '_blank')
  }

  const handleEditPlateNumber = (event: IParkingLotListEventData) => {
    if (event.parking_session_id) {
      setEditSessionPlateNumberDialogData({ sessionId: event.parking_session_id, plateNumber: event.plate_number })
      setOpenEditSessionPlateNumberDialog(true)
    } else {
      setEditPlateNumberEvent({ id: event.id, plateNumber: event.plate_number })
      setOpenEditPlateNumberDialog(true)
    }
  }

  const handleChangeType = (event: IParkingLotListEventData) => {
    const action = 'change-type'
    if (isLoading(action)) {
      return
    }

    startLoading(action)

    api.updateParkingLotEvent({
      event_id: event.id,
      type: event.type == 'entry' ? 'exit' : 'entry',
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.eventTypeChanged'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotChangeEventType'))
      })
  }

  const handleEditTotalAmount = (event: IParkingLotListEventData) => {
    setEditTotalAmountSession({ id: event.parking_session_id, amountUnpaid: event.amount_unpaid, amountPaid: event.amount_paid })
    setOpenEditTotalAmountDialog(true)
  }

  const handleArchive = (event: IParkingLotListEventData, add_ignore?: boolean) => {
    const action = 'archive'
    if (isLoading(action)) {
      return
    }

    startLoading(action)

    api.updateParkingLotEvent({
      event_id: event.id,
      archive: true,
      ...(add_ignore && { add_ignore: true }),
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.eventArchived'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotArchiveEvent'))
      })
  }

  const handleUnarchive = (event: IParkingLotListEventData) => {
    const action = 'unarchive'
    if (isLoading(action)) {
      return
    }

    startLoading(action)

    api.updateParkingLotEvent({
      event_id: event.id,
      archive: false,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.eventUnarchived'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotUnarchiveEvent'))
      })
  }

  const handleMassArchive = () => {
    const eventIds = (events || []).filter(event => !event.parking_session_id && !event.archived && selected.indexOf(event.id) !== -1).map(event => event.id)

    if (!eventIds.length) {
      return
    }

    const action = 'mass-archive'
    if (isLoading(action)) {
      return
    }

    setWithSelectedEl(null)
    startLoading(action)

    api.massUpdateParkingLotEventArchive({
      event_ids: eventIds,
      value: true,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.eventsArchived'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotArchiveEvents'))
      })
  }

  const handleMassUnarchive = () => {
    const eventIds = (events || []).filter(event => !event.parking_session_id && event.archived && selected.indexOf(event.id) !== -1).map(event => event.id)

    if (!eventIds.length) {
      return
    }

    const action = 'mass-unarchive'
    if (isLoading(action)) {
      return
    }

    setWithSelectedEl(null)
    startLoading(action)

    api.massUpdateParkingLotEventArchive({
      event_ids: eventIds,
      value: false,
    })
      .then(data => {
        if (!data || !data.status) {
          throw new Error
        }

        load(() => stopLoading(action))

        showSuccess(t('messages.eventsUnarchived'))
      })
      .catch(() => {
        stopLoading(action)
        showError(t('alerts.couldNotUnarchiveEvents'))
      })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setLoadChangePageList(page + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoadChangePerPageList(parseInt(event.target.value, 10))
  }

  return (
    <>
      {enableList === false && (
        <Container maxWidth="md" style={{ paddingTop: 36 }}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} style={{color: '#aaa', textAlign: 'center'}}>
            <IconSearch style={{fontSize: 80}}/>
            {t('listSearchInvite')}
          </Box>
        </Container>
      )}

      {enableList && events && listLoading && (
        <LinearProgress style={{ height: 1, marginBottom: -1 }} />
      )}

      {enableList && events && !events.length && (
        <Container maxWidth="md" style={{ paddingTop: 36 }}>
          <Alert severity="info">{t('noRecords')}</Alert>
        </Container>
      )}

      {enableList && (!events || Boolean(events.length)) && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                {!events && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {events && (
                  <TableRow>
                    {modify && <TableCell style={{ padding: 0, width: 38 }}>
                      <Checkbox size="small"
                                indeterminate={selected.length > 0 && selected.length < events.length}
                                checked={events.length > 0 && selected.length === events.length}
                                onClick={(e) => handleSelectAll(e)} />
                    </TableCell>}
                    <TableCell style={{ width: '1%' }}>{t('zone')}</TableCell>
                    <TableCell colSpan={2} style={{ whiteSpace: 'nowrap' }}>{t('plateNumber')}</TableCell>
                    <TableCell style={{ width: '30%' }}>{t('droveIn')}</TableCell>
                    <TableCell style={{ width: '30%' }}>{t('droveOut')}</TableCell>
                    <TableCell>{t('duration')}</TableCell>
                    <TableCell>{t('paid')}</TableCell>
                    {modify && <TableCell style={{ width: '150px' }} />}
                    <TableCell style={{ padding: 0, width: 42 }}>
                      <ShowImageThumbnailsToggleButton
                        value="true"
                        selected={showImageThumbnails}
                        size="small"
                        onChange={() => {
                          setShowImageThumbnails(!showImageThumbnails)
                        }}
                      >
                        <IconImage />
                      </ShowImageThumbnailsToggleButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!events && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                      <Skeleton style={{ margin: '8px 0' }} />
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {events && events
                  .map((event, key) => {
                    const closedSession = event.parking_session_id && event.exit_occurred_at && !event.amount_unpaid && !event.amount_additional_unpaid

                    return <React.Fragment key={key}>
                      <TableRow
                        {...(closedSession && { className: fadeTableRow })}
                        {...(event.id !== expand && { style: { cursor: 'pointer' }, hover: true, onClick: (() => handleExpand(event.id)) })}
                      >
                        {modify && <TableCell style={{ padding: '7px 0 0', verticalAlign: 'top' }}>
                          <Checkbox size="small" checked={isSelected(event.id)} onClick={() => handleSelect(event.id)} />
                        </TableCell>}
                        <ListTableCell>
                          {
                            (event.zone_id && outputEventZone(zones.find(zone => zone.id == event.zone_id)))
                            || <IconError fontSize="small" color="primary" style={{ verticalAlign: 'top' }} />
                          }
                        </ListTableCell>
                        {(event.plate_number_image_url && (
                          <ListPlateNumberImageTableCell>
                            <img src={event.plate_number_image_url} width={100} style={{ verticalAlign: 'top' }} />
                          </ListPlateNumberImageTableCell>
                        ))}
                        <ListTableCell colSpan={event.plate_number_image_url ? 1 : 2}>
                          <ListIconButton
                            icon={<IconSearch />}
                            color="secondary"
                            onClick={(e) => handleSearchPlateNumber(e, event.plate_number)}
                          />
                          <ListIconButton
                            icon={<IconSearchInNewWindow />}
                            color="secondary"
                            spacingLeft
                            onClick={(e) => handleSearchPlateNumberInNewWindow(e, event.zone_id, event.plate_number)}
                          />

                          <span className={plateNumberClass} style={{
                            marginLeft: 12,
                            ...(!closedSession && !event.plate_number.match(/^[A-Z]{2}\d{1,4}$/i) && { color: theme.palette.primary.main }),
                          }}>{event.plate_number}</span>

                          {modify && (
                            <ListIconButton
                              icon={<IconEdit />}
                              spacingLeft
                              onClick={() => handleEditPlateNumber(event)}
                            />
                          )}

                          {(event.parking_session_id && !event.parking_session_exit_at) ? (
                            <>
                              {event.subscription && event.permit && (
                                <Tooltip title={`${t('subscription')} / ${t('permit')}`} placement="top" arrow={true}>
                                  <IconSubscription fontSize="small" style={{ verticalAlign: 'top', marginLeft: 6, color: 'rgba(0, 0, 0, 0.38)' }} />
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            <>
                              {event.subscription && (
                                <Tooltip title={t('subscription')} placement="top" arrow={true}>
                                  <IconSubscription fontSize="small" color="primary" style={{ verticalAlign: 'top', marginLeft: 6 }} />
                                </Tooltip>
                              )}
                              {event.permit && (
                                <Tooltip title={t('permit')} placement="top" arrow={true}>
                                  <IconSubscription fontSize="small" color="secondary" style={{ verticalAlign: 'top', marginLeft: 6 }} />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </ListTableCell>
                        <ListTableCell>
                          {event.type == ParkingLotListEventType.Entry ? (
                            <>
                              <EventDatetime value={event.occurred_at} />
                              {(event.id == expand || showImageThumbnails) && (
                                <>
                                  <EventCameraName value={event.camera_name} />
                                  {event.plate_number_original && <EventPlateNumberOriginal value={event.plate_number_original} />}
                                </>
                              )}
                              {event.id == expand && modify && (
                                <Tooltip
                                  title={<>
                                    {`Notikums #: ${event.id}`}
                                    {event.parking_session_id && <><br />{`Sesija #: ${event.parking_session_id}`}</>}
                                  </>}
                                  placement="top"
                                  arrow interactive
                                >
                                  <IconInfo fontSize="inherit" style={{ verticalAlign: 'top', marginTop: 3, marginLeft: 12 }} />
                                </Tooltip>
                              )}
                              {event.id == expand && !event.parking_session_id && modify && (
                                <ListIconButton
                                  icon={<IconSwap />}
                                  spacingLeft
                                  disabled={isLoading('change-type')}
                                  onClick={() => handleChangeType(event)}
                                />
                              )}
                              {(event.id == expand || showImageThumbnails) && (
                                <Box paddingTop={1} style={{ ...(event.id != expand && { maxWidth: 200 }) }}>
                                  {event.image_url ? <ImageZoom src={event.image_url} /> : <ImagePlaceholder />}
                                </Box>
                              )}
                            </>
                          ) : (
                            <IconError fontSize="small" color="primary" style={{ verticalAlign: 'top' }} />
                          )}
                        </ListTableCell>
                        <ListTableCell>
                          {
                            (event.type == ParkingLotListEventType.Exit && <EventDatetime value={event.occurred_at} />)
                            || (event.exit_occurred_at && <EventDatetime value={event.exit_occurred_at} />)
                            || (event.parking_session_exit_at && <span style={{ color: theme.palette.primary.main }}><EventDatetime value={event.parking_session_exit_at} /></span>)
                            || (event.parking_session_id && <IconTimer fontSize="small" style={{ verticalAlign: 'top' }} />)
                            || <IconError fontSize="small" color="primary" style={{ verticalAlign: 'top' }} />
                          }
                          {(event.id == expand || showImageThumbnails) && (
                            (event.type == ParkingLotListEventType.Exit && (
                              <>
                                <EventCameraName value={event.camera_name} />
                                {event.plate_number_original && <EventPlateNumberOriginal value={event.plate_number_original} />}
                              </>
                            )) || (event.exit_occurred_at && (
                              <>
                                <EventCameraName value={event.exit_camera_name} />
                                {event.exit_plate_number_original && <EventPlateNumberOriginal value={event.exit_plate_number_original} />}
                              </>
                            ))
                          )}
                          {(event.type == ParkingLotListEventType.Exit || event.exit_camera_event_id) && event.id == expand && modify && (
                            <Tooltip title={`Notikums #: ${event.type == ParkingLotListEventType.Exit ? event.id : event.exit_camera_event_id}`} placement="top" arrow interactive>
                              <IconInfo fontSize="inherit" style={{ verticalAlign: 'top', marginTop: 3, marginLeft: 12 }} />
                            </Tooltip>
                          )}
                          {event.type == ParkingLotListEventType.Exit && event.id == expand && modify && (
                            <ListIconButton
                              icon={<IconSwap />}
                              spacingLeft
                              disabled={isLoading('change-type')}
                              onClick={() => handleChangeType(event)}
                            />
                          )}
                          {(event.type == ParkingLotListEventType.Exit || event.exit_occurred_at) && (event.id == expand || showImageThumbnails) && (
                            <Box paddingTop={1} style={{ ...(event.id != expand && { maxWidth: 200 }) }}>
                              {(event.type == ParkingLotListEventType.Exit ? event.image_url : event.exit_image_url) ? <ImageZoom src={event.type == ParkingLotListEventType.Exit ? event.image_url : event.exit_image_url} /> : <ImagePlaceholder />}
                            </Box>
                          )}
                        </ListTableCell>
                        <ListTableCell>
                          {
                            (event.exit_occurred_at && <span style={{ ...(differenceInMinutes(new Date(event.exit_occurred_at), new Date(event.occurred_at)) < 1 && { color: theme.palette.primary.main }) }}><SessionDuration entry={event.occurred_at} exit={event.exit_occurred_at} /></span>)
                            || (event.parking_session_exit_at && <span style={{ color: theme.palette.primary.main }}><SessionDuration entry={event.occurred_at} exit={event.parking_session_exit_at} /></span>)
                            || (event.parking_session_id && <SessionDuration entry={event.occurred_at} exit={durationNowDate} />)
                          }
                        </ListTableCell>
                        <ListTableCell>
                          {event.parking_session_id && (
                            <>
                              <span style={{ color: event.amount_unpaid ? 'inherit' : 'green' }}>{formatMoney(event.amount_paid)}</span>
                              {Boolean(event.amount_unpaid) && <> (<span style={{ color: event.amount_unpaid > 0 ? 'red' : 'orange' }}>{formatMoney(event.amount_unpaid)}</span>)</>}
                              {event.parking_session_has_special_rate && (
                                <Tooltip title={<>
                                  {`${t('specialRate')}${(event.parking_session_special_rate_name ? `: ${event.parking_session_special_rate_name}` : '')}${(event.parking_session_special_rate_offer_id ? `, ID ${event.parking_session_special_rate_offer_id}` : '')}`}
                                  {event.parking_session_special_rate_assigned_user_name && <><br />{`${t('assignedBy')}: ${event.parking_session_special_rate_assigned_user_name}`}</>}
                                  {event.parking_session_special_rate_assigned_at && <><br />{`@ ${format(new Date(event.parking_session_special_rate_assigned_at), 'dd.MM.yyyy HH:mm')}`}</>}
                                </>} placement="top" arrow={true} interactive={event.id == expand}>
                                  <SpecialRateIcon color="primary" style={{ width: 18, height: 18, verticalAlign: 'top', margin: '0 0 -1px 4px' }} />
                                </Tooltip>
                              )}
                              {event.autopay && (
                                <Tooltip title="Mobilly Automatic" placement="top" arrow={true}>
                                  <AutomaticIcon color="primary" style={{ width: 16, height: 16, verticalAlign: 'top', marginTop: 1, marginLeft: 4 }} />
                                </Tooltip>
                              )}
                              {(Boolean(event.amount_additional_unpaid) || Boolean(event.amount_additional_paid)) && <>
                                <span style={{ color: theme.palette.text.primary }}>{` + `}</span>
                                <span style={{ color: event.amount_additional_unpaid ? 'inherit' : 'green' }}>{formatMoney(event.amount_additional_paid)}</span>
                                {Boolean(event.amount_additional_unpaid) && <> (<span style={{ color: event.amount_additional_unpaid > 0 ? 'red' : 'orange' }}>{formatMoney(event.amount_additional_unpaid)}</span>)</>}
                              </>}
                              {event.id == expand && event.parking_session_id && event.parking_session_exit_at && modify && (
                                <ListIconButton
                                  icon={<IconEdit />}
                                  spacingLeft
                                  onClick={() => handleEditTotalAmount(event)}
                                />
                              )}
                            </>
                          )}
                          {!event.parking_session_id && (
                            <>{`—`}</>
                          )}
                        </ListTableCell>
                        {modify && (
                          <ListTableCell>
                            {event.id == expand && (
                              <ButtonGroup orientation="vertical">
                                {!event.parking_session_id && event.type == ParkingLotListEventType.Entry && !event.archived && (
                                  <ListItemActionButton
                                    startIcon={<IconPlay />}
                                    onClick={() => handleStart(event)}
                                  >
                                    {t('start')}
                                  </ListItemActionButton>
                                )}
                                {!event.parking_session_id && !event.archived && (event.plate_number_original || event.exit_plate_number_original) && (
                                  <ListArchiveEventButton
                                    startIcon={<IconArchive />}
                                    disabled={isLoading('archive')}
                                    onClick={() => handleArchive(event)}
                                  >
                                    {t('archive')}
                                  </ListArchiveEventButton>
                                )}
                                {!event.parking_session_id && !event.archived && !event.plate_number_original && !event.exit_plate_number_original && <EventArchiveButton event={event} handleArchive={handleArchive} noBorderTopRadius={event.type == ParkingLotListEventType.Entry} />}
                                {!event.parking_session_id && event.archived && (
                                  <ListArchiveEventButton
                                    startIcon={<IconUnarchive />}
                                    disabled={isLoading('unarchive')}
                                    onClick={() => handleUnarchive(event)}
                                  >
                                    {t('unarchive')}
                                  </ListArchiveEventButton>
                                )}
                                {event.parking_session_id && (
                                  <ListItemActionButton
                                    startIcon={<SpecialRateIcon />}
                                    onClick={() => handleEditSpecialRate(event)}
                                  >
                                    {t('rate')}
                                  </ListItemActionButton>
                                )}
                                {event.parking_session_id && event.parking_session_exit_at && (
                                  <ListItemActionButton
                                    startIcon={<RecalculateIcon />}
                                    disabled={isLoading('recalculate')}
                                    onClick={() => handleRecalculateAmount(event)}
                                  >
                                    {t('recalculate')}
                                  </ListItemActionButton>
                                )}
                                {event.parking_session_id && (
                                  <ListItemActionButton
                                    startIcon={<IconPayment />}
                                    onClick={() => handleAttachPayment(event)}
                                  >
                                    {t('payment')}
                                  </ListItemActionButton>
                                )}
                                {event.parking_session_id && !event.exit_occurred_at && !event.parking_session_exit_at && !event.autopay && (
                                  <ListItemActionButton
                                    startIcon={<AutomaticIcon style={{ width: 16, height: 16, marginLeft: 2 }} />}
                                    disabled={isLoading('start-autopay')}
                                    onClick={() => handleStartAutopay(event)}
                                  >
                                    Automatic
                                  </ListItemActionButton>
                                )}
                                {event.parking_session_id && !event.exit_occurred_at && !event.parking_session_exit_at && <SessionStopButton event={event} handleStop={handleStop} handleStopWithoutEvent={handleStopWithoutEvent} />}
                                {event.parking_session_id && <SessionCancelButton event={event} handleCancel={handleCancel} />}
                              </ButtonGroup>
                            )}
                          </ListTableCell>
                        )}
                        <ListExpandTableCell style={{ color: theme.palette.text.primary }}>{event.id == expand ? <ExpandLess style={{ cursor: 'pointer' }} onClick={() => handleExpand(event.id)} /> : <ExpandMore />}</ListExpandTableCell>
                      </TableRow>

                      {event.id == expand && event.parking_session_id && (
                        <TableRow>
                          <TableCell colSpan={modify ? 10 : 8} style={{ borderTop: '2px solid transparent' }}>
                            <ListSessionPayments session={{ id: event.parking_session_id, entry_at: event.occurred_at, exit_at: event.exit_occurred_at || event.parking_session_exit_at }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container alignItems="center">
            {modify && (
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<IconSelected />}
                  disabled={!selected.length || (!selectedEventsCount && !selectedArchivedEventsCount)}
                  onClick={handleWithSelected}
                >{selected.length} izvēlēti</Button>
                <Menu
                  elevation={1}
                  anchorEl={withSelectedEl}
                  keepMounted
                  open={Boolean(withSelectedEl)}
                  onClose={handleWithSelectedClose}
                >
                  {Boolean(selectedEventsCount) && <WithSelectedMenuItem icon={<IconArchive fontSize="small" />} text={`${t('archive')} (${selectedEventsCount})`} disabled={isLoading('mass-archive')} onClick={() => handleMassArchive()} />}
                  {Boolean(selectedArchivedEventsCount) && <WithSelectedMenuItem icon={<IconUnarchive fontSize="small" />} text={`${t('unarchive')} (${selectedArchivedEventsCount})`} disabled={isLoading('mass-unarchive')} onClick={() => handleMassUnarchive()} />}
                </Menu>
              </Grid>
            )}
            <Grid item style={{ flex: 'auto' }}>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={totalEvents}
                rowsPerPage={listPerPage}
                page={listPage - 1}
                labelRowsPerPage={`${t('show')}:`}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count !== -1 ? count : '...'}`}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Grid>
          </Grid>
        </>
      )}
      {modify && (
        <>
          <StartSessionDialog open={openStartSessionDialog} setOpen={setOpenStartSessionDialog} session={startSessionSession} />
          <StopSessionDialog open={openStopSessionDialog} setOpen={setOpenStopSessionDialog} session={stopSessionSession} />
          <EditPlateNumberDialog open={openEditPlateNumberDialog} setOpen={setOpenEditPlateNumberDialog} event={editPlateNumberEvent} />
          <EditSessionPlateNumberDialog open={openEditSessionPlateNumberDialog} setOpen={setOpenEditSessionPlateNumberDialog} data={editSessionPlateNumberDialogData} />
          <EditTotalAmountDialog open={openEditTotalAmountDialog} setOpen={setOpenEditTotalAmountDialog} session={editTotalAmountSession} />
          <EditSpecialRateDialog open={openEditSpecialRateDialog} setOpen={setOpenEditSpecialRateDialog} data={editSpecialRateDialogData} />
          <AttachPaymentDialog open={openAttachPaymentDialog} setOpen={setOpenAttachPaymentDialog} data={attachPaymentDialogData} />
        </>
      )}
    </>
  )
}

export default List
