import React, { FunctionComponent, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { SelectField } from 'components/elements/SelectField'
import { useTranslation } from 'react-i18next'
import { useApi } from 'components/providers/ApiProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { usePermits } from 'components/views/subscriptions/permits/PermitsProvider'
import { TextField } from 'components/elements/TextField'
import { DateField } from 'components/elements/DateField'
import { formatDateFieldToISO, parseDateField, yupDateField } from 'utils'
import { format } from 'date-fns'

type Props = {
  open: boolean
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const AddPermitDialog: FunctionComponent<AllProps> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation()
  const api = useApi()
  const { addZones: zones, setLoadList } = usePermits()
  const { showSuccess, showError } = useMessage()

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (zones.length == 1) {
      formal.change('zone', zones[0].zone_group_id)
    }
  }, [zones])

  const initialValues = {
    zone: 0,
    from: format(new Date, 'dd.MM.yyyy'),
    till: '',
    count: 1,
    plateNumbers: '',
    client: '',
    notes: '',
  }

  const schema = yup.object().shape({
    zone: yup.number().required().min(1),
    from: yupDateField({ required: true }),
    till: yupDateField(),
    count: yup.number().required().integer().min(1).max(1000),
    plateNumbers: yup.string().matches(/^[0-9a-zA-Z, \n]*$/),
    client: yup.string().matches(/^2\d{7}$/, { excludeEmptyString: true }),
    notes: yup.string().trim(),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: async values => {
      values = schema.cast(values)

      await api.createPermit({
        zone_group_id: values.zone,
        from: formatDateFieldToISO(values.from),
        ...(values.till && {
          till: formatDateFieldToISO(values.till),
        }),
        count: values.count,
        ...(values.plateNumbers && {
          plate_numbers: values.plateNumbers.split(/[,\n]/).map(plateNumber => plateNumber.replace(/\s/g, '').toUpperCase()).filter((plateNumber, index, self) => plateNumber && self.indexOf(plateNumber) === index),
        }),
        client: values.client || undefined,
        notes: values.notes || undefined,
      })
        .then(response => {
          if (!response || !response.status) {
            throw new Error
          }

          handleClose()

          if (zones.length > 1) {
            formal.change('zone', initialValues.zone)
          }
          formal.change('from', initialValues.from)
          formal.change('till', initialValues.till)
          formal.change('count', initialValues.count)
          formal.change('plateNumbers', initialValues.plateNumbers)
          formal.change('client', initialValues.client)
          formal.change('notes', initialValues.notes)

          setLoadList()

          showSuccess(t('messages.permitAssigned'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 10:
              message = t('subscriptionPurchase.alertSubscriptionUpdateFailedVehicleOverlapBody', { plates: error.extra.plates.join(', ') })
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotAssignPermit')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onClose={handleClose}>
      <DraggableDialogTitle>{t('newPermit')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            <SelectField
              GridProps={{ xs: 12 }}
              label={t('zone')}
              required
              optionsCustom={[
                {
                  label: zones.length > 1 ? t('select') : '',
                  id: 0,
                },
                ...zones.map(zone => {
                  const localization = zone.zone_localizations.find(l => l.language == i18n.language)

                  return {
                    label: localization && localization.name ? `${localization.name} (${zone.zone_code})` : zone.zone_code,
                    id: zone.zone_group_id,
                  }
                }),
              ]}
              {...formal.getFieldProps('zone')}
              disabled={zones.length <= 1 || formal.isSubmitting}
            />
            <DateField
              GridProps={{ xs: 6 }}
              label={t('startDate')}
              required
              disablePast
              {...(formal.values.till && { maxDate: parseDateField(formal.values.till) })}
              {...formal.getFieldProps('from')}
            />
            <DateField
              GridProps={{ xs: 6 }}
              label={t('endDate')}
              clearable
              disablePast
              {...(formal.values.from && { minDate: parseDateField(formal.values.from) })}
              {...formal.getFieldProps('till')}
            />
            <TextField
              GridProps={{ xs: 12 }}
              type="number"
              label={t('quantity')}
              required
              inputProps={{
                min: 1,
                max: 1000,
              }}
              {...formal.getFieldProps('count')}
            />
            <TextField
              GridProps={{ xs: 12 }}
              label={t('plateNumbers')}
              multiline
              autoComplete="off"
              helperText={`${t('example')}: AA0001, AA0002`}
              inputProps={{
                style: {
                  fontFamily: '"Roboto Mono", monospace',
                  lineHeight: '21px',
                  textTransform: 'uppercase',
                  marginTop: -1,
                  marginBottom: -1,
                  maxHeight: 63,
                  overflow: 'auto',
                },
              }}
              {...formal.getFieldProps('plateNumbers')}
            />
            <TextField
              GridProps={{ xs: 12 }}
              label={t('clientPhoneNumber')}
              helperText={`${t('example')}: 26420001`}
              {...formal.getFieldProps('client')}
            />
            <TextField
              GridProps={{ xs: 12 }}
              label={t('notes')}
              {...formal.getFieldProps('notes')}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('assign')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default AddPermitDialog
